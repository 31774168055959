import { useTranslation } from 'react-i18next';

import React from 'react';

import { IFailedTariffId } from '@EZenkof/common-recruiter-library';

import FalseFillCheckMarkSvg from '../../../../../../../common/svg/false-fill-check-mark.svg';
import TrueFillCheckMarkSvg from '../../../../../../../common/svg/true-fill-check-mark.svg';

import styles from './styles.module.scss';

interface Props {
  failedTariffId: IFailedTariffId;
}

const PaymentErrorInfoComponent = ({ failedTariffId }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t('order.paymentError')}</h1>

      {failedTariffId.reason === 'noValidCard' ? (
        <>
          <ul>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.paymentError1')}</p>
            </li>
          </ul>
          <p>{t('order.paymentError2')}</p>
        </>
      ) : (
        <>
          <p>{t('order.paymentError3')}</p>
          <ul>
            <li>
              <TrueFillCheckMarkSvg />
              <p>{t('order.paymentError4')}</p>
            </li>
            <li>
              <TrueFillCheckMarkSvg />
              <p>{t('order.paymentError5')}</p>
            </li>
          </ul>
          <p>{t('order.paymentError6')}</p>
        </>
      )}
    </div>
  );
};

export default PaymentErrorInfoComponent;
