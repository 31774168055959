import React, { ReactElement, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onChange: (value: any) => void;
  error?: boolean;
  onEnterPress?: () => void;
  children?: ReactElement;
  icon?: ReactElement;
  onFocus?: () => void;
  onBlur?: () => void;
  passivePlaceholder?: string;
}

const Input = ({
  onFocus,
  onBlur,
  className,
  children,
  error,
  placeholder,
  onEnterPress,
  icon,
  onChange,
  passivePlaceholder,
  ...rest
}: Props): ReactElement => {
  const [isShowedPressEnter, setIsShowedPressEnter] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);

  const [inputStyles, setInputStyles] = useState<string>(`${styles.input} ${error ? styles.error : ''}`);

  const { t } = useTranslation();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnterPress) onEnterPress();
  };

  const handleOnFocus = () => {
    setIsFocused(true);

    onFocus?.();
  };

  const handleOnBlur = () => {
    setIsFocused(false);

    onBlur?.();
  };

  useEffect(() => {
    setInputStyles(
      `${styles.input} ${error ? styles.error : ''} ${className !== 'undefined' && !!className?.length ? className : ''}`,
    );

    return () => {
      setInputStyles(`${styles.input} ${error ? styles.error : ''}`);
    };
  }, [className, error]);

  useEffect(() => {
    setIsShowedPressEnter(false);

    const handler = setTimeout(() => {
      if (!!rest.value && onEnterPress && isFocused) setIsShowedPressEnter(true);
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [rest.value, isFocused]);

  return (
    <label className={styles.container}>
      {
        (!children && !!rest.value)
          ? <label
            className={`${styles.label} ${isFocused || !!rest.value ? styles.focused : ''}`}
          >
            {placeholder}
          </label>
          : children
      }

      <div className={styles.input__container}>
        <input
          className={inputStyles}
          {...rest}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={passivePlaceholder ?? placeholder}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />

        <div className={styles.icon}>{icon}</div>
      </div>

      {
        isShowedPressEnter
          ? <p className={styles.helper}>{t('common.pressEnter')}</p>
          : null
      }
    </label>
  );
};

export default Input;
