import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from 'common/helper/hooks/useAuth';

import { useState } from 'react';

import AccountSvg from 'common/svg/account.svg';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../store/interface/store.interface';

import { formatNumberUtil } from '../../helper/utils/format-number.util';

import { tariffs } from '../../helper/consts/tariffs';

import styles from './styles.module.scss';

import AccountPopupComponent from './components/account-popup/account-popup.component';
import HeaderPagesComponent from './components/header-pages/header-pages.component';
import LanguagePopupComponent from './components/language-popup/language-popup.component';

export const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);


  const { t } = useTranslation();
  const location = useLocation();
  const { logout, user } = useAuth();
  const { workspaceId: workspaceIdParam } = useParams();

  const { info } = useAppSelector((state) => state.payments);
  const { limit: availableParseLimit } = useAppSelector((state) => state.availableParseLimitSlice);

  const getAvailableLimit = (availableParseLimit: number | null) =>
    typeof availableParseLimit === 'number' &&
    (typeof info.currentTariffId === 'number' || (info.trialEndDate ?? 0) > Date.now())
      ? `${formatNumberUtil(availableParseLimit)} / ${formatNumberUtil(tariffs[info.currentTariffId ?? 0].limits)}`
      : t('common.unavaliable');

  const handlePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  return (
    <>
      <header className={styles.container}>
        <HeaderPagesComponent workspaceIdParam={workspaceIdParam}/>

        {user && location.pathname.includes('profile') && (
          <div className={styles.profile}>
            <div className={styles.limits}>
              <p>{t('header.limits')}</p>
              <p>{getAvailableLimit(availableParseLimit)}</p>
            </div>

            <button onClick={handlePopup}>
              <AccountSvg />
            </button>
          </div>
        )}

        <LanguagePopupComponent/>
      </header>

      <AccountPopupComponent
        isPopupOpen={isPopupOpen}
        handlePopup={handlePopup}
        logout={logout}
        workspaceIdParam={workspaceIdParam}
      />
    </>
  );
};

export default Header;
