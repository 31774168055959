import Modal from 'common/components/modal/modal.component';
import React, { ReactNode } from 'react';
import CloseSVG from 'common/svg/close.svg';
import Button from 'common/components/button/button.component';

import { updateVacancyGuideReducer } from '../../../../store/slices/guides/vacancies-guide.slice';
import { useAppDispatch } from '../../../../store/interface/store.interface';

import styles from './styles.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
  children?: ReactNode;
}

const StartTipModal = ({ active, setActive, children }: Props) => {
  const dispatch = useAppDispatch();

  const handleOpenGuide = () => dispatch(updateVacancyGuideReducer({
    step: 0,
    run: true,
  }));

  const handleClose = () => {
    setActive();

    handleOpenGuide();
  };

  if (!active) return null;

  return (
    <Modal active={active} setActive={handleClose}>
      <div className={styles.container}>
        {children}

        <iframe
          height="400"
          src="https://www.youtube.com/embed/g0RG1w8AnyU"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        >
        </iframe>

        <div className={styles.skip__container}>
          <Button onClick={handleClose} className={styles.skip}>
            <p>{children ? 'SKIP' : 'CLOSE'}</p>
          </Button>
        </div>

        <button className={styles.close} onClick={handleClose}>
          <CloseSVG />
        </button>
      </div>
    </Modal>
  );
};

export default StartTipModal;
