import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GetRecruitingVacancyById, IRecruitingVacancy } from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';

interface IGet {
  id: number;
  workspaceId: number;
  skipParsePlans?: boolean;
  updateOnlyRecent?: boolean;
}

interface IGetResult {
  vacancy: IRecruitingVacancy | null;
  skipParsePlans: boolean;
  updateOnlyRecent: boolean;
}

export const getVacancyByIdReducer = createAsyncThunk<IGetResult, IGet>(
  'vacancies/getVacancyById',
  async ({id,workspaceId, skipParsePlans, updateOnlyRecent}, {rejectWithValue}) => {
    try {
      const result = await GetRecruitingVacancyById(id, { workspaceId });

      return {vacancy: result, skipParsePlans: !!skipParsePlans, updateOnlyRecent: !!updateOnlyRecent};
    }catch(error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getVacancyByIdReducerPending = (state: IVacanciesSlice) => {};

export const getVacancyByIdReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<IGetResult>) => {
  if (!action.payload.updateOnlyRecent)
    state.vacancies = state.vacancies.map((vacancy) => {
      if (vacancy.id === action.payload.vacancy?.id)
        return { 
          ...vacancy, 
          ...action.payload.vacancy,
          parsePlans: action.payload.skipParsePlans
            ? vacancy.parsePlans
            : action.payload.vacancy.parsePlans,
        };
      return vacancy;
    });

  state.recentVacancy = action.payload.vacancy;
};

export const getVacancyByIdReducerRejected = (state: IVacanciesSlice) => {};
