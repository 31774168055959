import { GetPaymentInfo, IPaymentInfo } from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IPaymentsSlice } from '../payments.slice';

interface IGetResult extends IPaymentInfo {}

export const getPaymentInfoReducer = createAsyncThunk<IGetResult, number>(
  'paymentsSlice/getPaymentsInfo',
  async (workspaceId, {rejectWithValue}) => {
    try {
      return await GetPaymentInfo({workspaceId});
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getPaymentInfoReducerPending = (state: IPaymentsSlice) => {
  state.isLoading = true;
};

export const getPaymentInfoReducerFulfilled = (state: IPaymentsSlice, action: PayloadAction<IGetResult>) => {
  state.info = {...state.info, ...action.payload};
  state.isLoading = false;
};

export const getPaymentInfoReducerRejected = (state: IPaymentsSlice) => {
  state.isLoading = false;
};

