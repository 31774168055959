import { GetWorkspaceById, IWorkspace } from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IWorkspaceSlice } from '../workspace.slice';

export const getWorkspaceByIdReducer = createAsyncThunk<IWorkspace | null, number>(
  'workspace/getWorkspaceById',
  async (workspaceId, {rejectWithValue}) => {
    try{
      return await GetWorkspaceById({workspaceId});
    }catch(error: any){
      return rejectWithValue(error.message);
    }
  },
);

export const getWorkspaceByIdReducerPending = (state: IWorkspaceSlice) => {
  state.workspace = null;
  state.isLoading = true;
};

export const getWorkspaceByIdReducerFulfilled = (state: IWorkspaceSlice, action: PayloadAction<IWorkspace | null>) => {
  state.workspace = action.payload;
  state.isLoading = false;
};

export const getWorkspaceByIdReducerRejected = (state: IWorkspaceSlice) => {
  state.isLoading = false;
};
