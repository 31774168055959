import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useLocalStorage } from 'common/helper/hooks/use-local-storage.hook';
import { getPaymentInfoReducer } from 'store/slices/payments/reducers/get-payment-info.reducer';
import { millisecondsInDay } from 'common/helper/consts/dates-milliseconds';
import { setFeedbackFrameType } from 'store/slices/feedback-frames/feedback-frames.slice';
import { useAppDispatch, useAppSelector } from 'store/interface/store.interface';
import { getAvailableParseLimitReducer } from 'store/slices/available-parse-limit/reducers/get-available-parse-limit.reducer';

import Modal from 'common/components/modal/modal.component';

import { EUserToWorkspaceRole } from '@EZenkof/common-recruiter-library';

import { findWorkspaceInUser } from '../../../common/helper/utils/find-workspace-in-user.util';
import { useAuth } from '../../../common/helper/hooks/useAuth';

import PaymentErrorComponent from './components/payment-error/payment-error.component';

interface Props {
  workspaceIdParam?: string;
}

const InfoLayer = ({workspaceIdParam}: Props) => {
  const [sessionsStatistic, setSessionsStatistic] = useLocalStorage<{
    lastSessionDate: number;
    session: number;
    isCollectedInitialFeedback?: boolean;
    isCollectedFinalFeedback?: boolean;
    isShowedTrialEndModal?: boolean;
    isShowedPaymentErrorModal?: boolean;
  }>('sessionsStatistic', {
    lastSessionDate: Date.now(),
    session: 1,
    isCollectedInitialFeedback: false,
    isCollectedFinalFeedback: false,
    isShowedTrialEndModal: false,
    isShowedPaymentErrorModal: false,
  });

  const dispatch = useAppDispatch();
  const {user} = useAuth();
  const location = useLocation();

  const {
    info: { trialEndDate, failedTariffId },
  } = useAppSelector((state) => state.payments);

  const handleClosePaymentErrorModal = () =>
    setSessionsStatistic({
      ...sessionsStatistic,
      session: sessionsStatistic?.session ?? 1,
      lastSessionDate: sessionsStatistic?.lastSessionDate ?? Date.now(),
      isShowedPaymentErrorModal: true,
    });

  const isUserAdminInWorkspace = () =>
    findWorkspaceInUser(user, workspaceIdParam)?.role === EUserToWorkspaceRole.ADMIN;

  useEffect(() => {
    const workspaceId = Number(workspaceIdParam);

    if (workspaceId) {
      dispatch(getPaymentInfoReducer(workspaceId));
      dispatch(getAvailableParseLimitReducer(workspaceId));
    }
  }, [location.pathname, workspaceIdParam]);

  const isShouldShowInitialFeedBack = () =>
    !sessionsStatistic?.isCollectedInitialFeedback &&
    (sessionsStatistic?.session ?? 0) >= 2;

  const isShouldShowFinalFeedback = () =>
    Number(trialEndDate) <= Date.now() + millisecondsInDay * 5 &&
    sessionsStatistic?.isCollectedInitialFeedback &&
    !sessionsStatistic?.isCollectedFinalFeedback;

  const isShouldIncrementSession = () =>
    sessionsStatistic?.lastSessionDate && sessionsStatistic.lastSessionDate + millisecondsInDay <= Date.now();

  useEffect(() => {
    if (!sessionsStatistic?.lastSessionDate) setSessionsStatistic({ lastSessionDate: Date.now(), session: 1 });
    else if (trialEndDate) {
      const isTrialActiveOrEndShowed =
        trialEndDate >= Date.now() || (trialEndDate < Date.now() && sessionsStatistic.isShowedTrialEndModal);

      if (isTrialActiveOrEndShowed) {
        if (isShouldShowFinalFeedback()) {
          dispatch(setFeedbackFrameType({ activeType: 'finalFeedback' }));

          setSessionsStatistic({
            ...sessionsStatistic,
            isCollectedFinalFeedback: true,
            session: sessionsStatistic.session + 1,
          });
        } else if (isShouldShowInitialFeedBack()) {
          dispatch(setFeedbackFrameType({ activeType: 'initialFeedback' }));

          setSessionsStatistic({
            ...sessionsStatistic,
            lastSessionDate: Date.now(),
            isCollectedInitialFeedback: true,
            session: sessionsStatistic.session + 1,
          });
        } else if (isShouldIncrementSession())
          setSessionsStatistic({
            ...sessionsStatistic,
            lastSessionDate: Date.now(),
            session: sessionsStatistic.session + 1,
          });
      }
    }
  }, [trialEndDate]);

  if (failedTariffId && !sessionsStatistic?.isShowedPaymentErrorModal && isUserAdminInWorkspace())
    return (
      <Modal active={true} setActive={handleClosePaymentErrorModal}>
        <PaymentErrorComponent
          failedTariffId={failedTariffId}
          handleIsActive={handleClosePaymentErrorModal}
          workspaceIdParam={workspaceIdParam}
        />
      </Modal>
    );
  else return null;
};

export default InfoLayer;
