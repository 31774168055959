import { IUserToWorkspace, IWorkspace } from '@EZenkof/common-recruiter-library';
import { createSlice } from '@reduxjs/toolkit';

import {
  getWorkspaceByIdReducer,
  getWorkspaceByIdReducerFulfilled,
  getWorkspaceByIdReducerPending, getWorkspaceByIdReducerRejected,
} from './reducers/get-workspace-by-id.reducer';
import {
  getUsersToWorkspaceReducer, getUsersToWorkspaceReducerFulfilled,
  getUsersToWorkspaceReducerPending, getUsersToWorkspaceReducerRejected,
} from './reducers/get-users-to-workspace.reducer';
import {
  getUserToWorkspaceByUserIdReducer, getUserToWorkspaceByUserIdReducerFulfilled,
  getUserToWorkspaceByUserIdReducerPending, getUserToWorkspaceByUserIdReducerRejected,
} from './reducers/get-user-to-workspace-by-user-id.reducer';

export interface IWorkspaceSlice {
  workspace: IWorkspace | null;
  usersToWorkspaces: IUserToWorkspace[];
  usersToWorkspacesCount: number;
  isLoading: boolean;
}

const initialState: IWorkspaceSlice = {
  workspace: null,
  usersToWorkspaces: [],
  usersToWorkspacesCount: 0,
  isLoading: false,
};

const WorkspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkspaceByIdReducer.pending, getWorkspaceByIdReducerPending)
      .addCase(getWorkspaceByIdReducer.fulfilled, getWorkspaceByIdReducerFulfilled)
      .addCase(getWorkspaceByIdReducer.rejected, getWorkspaceByIdReducerRejected)

      .addCase(getUsersToWorkspaceReducer.pending, getUsersToWorkspaceReducerPending)
      .addCase(getUsersToWorkspaceReducer.fulfilled, getUsersToWorkspaceReducerFulfilled)
      .addCase(getUsersToWorkspaceReducer.rejected, getUsersToWorkspaceReducerRejected)

      .addCase(getUserToWorkspaceByUserIdReducer.pending, getUserToWorkspaceByUserIdReducerPending)
      .addCase(getUserToWorkspaceByUserIdReducer.fulfilled, getUserToWorkspaceByUserIdReducerFulfilled)
      .addCase(getUserToWorkspaceByUserIdReducer.rejected, getUserToWorkspaceByUserIdReducerRejected);
  },
});

export default WorkspaceSlice;
