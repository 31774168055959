import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetRecruitingVacancies,
  GetRecruitingVacanciesCount,
  IRecruitingVacancy,
  IFilterRecruitingVacanciesDto,
} from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';

interface IFilter {
  data: IFilterRecruitingVacanciesDto;
}

interface IFilterResult {
  vacancies: IRecruitingVacancy[];
  count: number;
}

export const filterVacanciesReducer = createAsyncThunk<IFilterResult, IFilter>(
  'vacancies/filterVacancies',
  async ({ data }, { rejectWithValue }) => {
    try {
      const [res, count] = await Promise.all([
        GetRecruitingVacancies(data), 
        GetRecruitingVacanciesCount(data),
      ]);

      return { vacancies: res ?? [], count: count ?? 0 };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterVacanciesReducerPending = (state: IVacanciesSlice) => {
  state.isLoading = true;
};

export const filterVacanciesReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<IFilterResult>) => {
  state.vacancies = action.payload.vacancies;
  state.count = action.payload.count;
  state.isLoading = false;

  // todo чтобы не скапливались неактуальные/удаленные кем-то старые вакансии
  const localStorageVacancies = JSON.parse(localStorage.getItem('ownVacancies') ?? '[]');

  const ownVacancies = localStorageVacancies.filter(
    (ownVacancy: any) => action.payload.vacancies.some((vacancy) => vacancy.id === ownVacancy.id),
  );

  localStorage.setItem('ownVacancies', JSON.stringify(ownVacancies));
};

export const filterVacanciesReducerRejected = (state: IVacanciesSlice) => {
  state.isLoading = false;
};
