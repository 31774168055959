import React, { ReactElement } from 'react';

import Loader from 'common/components/loader/loader';

import styles from './styles.module.scss';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: ReactElement;
  isLoading?: boolean;
  size?: 'big' | 'large' | 'medium' | 'none';
  radius?: 'normal';
}

const Button = ({ children, isLoading, className, size, radius, ...rest }: Props): ReactElement => {
  let padding = '12px';

  if (size === 'big') padding = '18px';
  if (size === 'large') padding = '4px 10px';
  if (size === 'none') padding = '';

  return (
    <button className={`${styles.button} ${className}`} {...rest} style={{ padding, borderRadius: radius && '5px' }}>
      {isLoading ? <Loader /> : children}
    </button>
  );
};

export default Button;
