import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import React, { useRef, useState } from 'react';

import Button from 'common/components/button/button.component';

import { EUserToWorkspaceRole } from '@EZenkof/common-recruiter-library';

import useClickOutside from '../../../../helper/hooks/use-click-outside.hook';

import MoneySvg from '../../../../svg/money.svg';
import LogoutSvg from '../../../../svg/logout.svg';
import { getWorkspacePath } from '../../../../helper/utils/get-workspace-path.util';
import { useAuth } from '../../../../helper/hooks/useAuth';

import DoneSvg from '../../../../svg/done.svg';

import PlusSVG from '../../../../svg/plus.svg';

import CreateWorkspaceModal from '../create-workspace-modal/create-workspace.modal';

import { findWorkspaceInUser } from '../../../../helper/utils/find-workspace-in-user.util';

import InviteUsersToWorkspaceModal
  from '../../../../modal/workspace/invite-users-to-workspace/invite-users-to-workspace.modal';

import styles from './styles.module.scss';

interface Props {
  isPopupOpen: boolean;
  handlePopup: () => void;
  workspaceIdParam?: string;
  logout: () => void;
}

interface UtilityProps extends Pick<Props, 'handlePopup' | 'workspaceIdParam'> {}

const AccountPopupComponent = ({ isPopupOpen, handlePopup, logout, workspaceIdParam }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef(null);

  useClickOutside(ref, handlePopup, isPopupOpen);

  const className = `${styles.container} ${isPopupOpen ? styles.show : ''}`;

  const navigateToPayments = () => {
    navigate(`/profile${getWorkspacePath(workspaceIdParam)}/payments`);
    
    handlePopup();
  };

  const handleLogout = () => {
    logout();

    handlePopup();
  };

  return (
    <div ref={ref} className={className}>
      { // todo delete env
        !process.env.REACT_APP_HIDE_WORKSPACE &&
      <Workspaces handlePopup={handlePopup} workspaceIdParam={workspaceIdParam}/>
      }
      <button title={t('header.profile.payments')} className={styles.button} onClick={navigateToPayments}>
        <MoneySvg />
        <p>{t('header.profile.payments')}</p>
      </button>
      <button title={t('header.profile.logout')} className={styles.button} onClick={handleLogout}>
        <LogoutSvg />
        <p>{t('header.profile.logout')}</p>
      </button>
    </div>
  );
};

export default AccountPopupComponent;

const Workspaces = ({handlePopup, workspaceIdParam}: UtilityProps) => {
  
  return (
    <div className={styles.workspaces}>
      <WorkspacesHeaderComponent handlePopup={handlePopup} workspaceIdParam={workspaceIdParam}/>
      <WorkspacesListComponent handlePopup={handlePopup} workspaceIdParam={workspaceIdParam}/>
    </div>
  );
};

const WorkspacesHeaderComponent = ({handlePopup, workspaceIdParam}: UtilityProps) => {
  const [isOpenInviteModal, setIsOpenInviteModal] = useState<boolean>(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const isUserAdminInWorkspace = () =>
    findWorkspaceInUser(user, workspaceIdParam)?.role === EUserToWorkspaceRole.ADMIN;

  const handleOpenInviteModal = () => setIsOpenInviteModal((prev) => !prev);

  const handleOpenManagementPage = () => {
    const workspaceId = Number(workspaceIdParam);

    if (workspaceId) navigate(`/profile/${workspaceId}/workspace`);

    handlePopup();
  };

  //todo добавить локализацию
  return (
    findWorkspaceInUser(user, workspaceIdParam)
      ? <div className={styles.current}>
        <div className={styles.top}>
          <span>{findWorkspaceInUser(user, workspaceIdParam)?.workspace.name[0]}</span>
          <div className={styles.item}>
            <b>{findWorkspaceInUser(user, workspaceIdParam)?.workspace.name}</b>
            <p>Workspace</p>
          </div>
        </div>

        <div className={styles.bottom}>
          <Button 
            size="large" 
            onClick={handleOpenInviteModal}
            disabled={!isUserAdminInWorkspace()}
          >
            <p>Invite members</p>
          </Button>
          <Button size="large" onClick={handleOpenManagementPage}>
            <p>Management</p>
          </Button>
        </div>
        <InviteUsersToWorkspaceModal
          active={isOpenInviteModal}
          setActive={handleOpenInviteModal}
          handlePopup={handlePopup}
          workspaceIdParam={workspaceIdParam}
        />
      </div>
      : null
  );
};

const WorkspacesListComponent = ({handlePopup, workspaceIdParam}: UtilityProps) => {
  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState<boolean>(false);

  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleCreateWorkspaceModalOpen = () => setIsCreateWorkspaceModalOpen((prev) => !prev);

  const handleCreateWorkspace = () => {
    handleCreateWorkspaceModalOpen();
  };

  const navigateToWorkspace = (workspaceId: number) => {
    if (workspaceId === Number(workspaceIdParam)) return;

    const newPath = location.pathname.replace(
      `/profile/${workspaceIdParam}`, 
      `/profile/${workspaceId}`,
    );
    
    navigate(newPath);
    handlePopup();
  };

  //todo добавить локализацию (title в тегах не забыть)
  return (
    <>
      <ul className={styles.list}>
        <li title="Create new workspace" onClick={handleCreateWorkspace}>
          <p>Create workspace</p>
          <PlusSVG />
        </li>
        <ul>
          {user?.userToWorkspaces?.map(userToWorkspace =>
            <li key={userToWorkspace.workspaceId} title={userToWorkspace.workspace.name}>
              <div
                className={styles.item}
                onClick={() => navigateToWorkspace(userToWorkspace.workspaceId)}
              >
                <span>{userToWorkspace.workspace.name[0]}</span>
                <b>{userToWorkspace.workspace.name}</b>
              </div>
              {userToWorkspace.workspaceId === Number(workspaceIdParam) && <DoneSvg />}
            </li>)}
        </ul>
      </ul>
      <CreateWorkspaceModal
        active={isCreateWorkspaceModalOpen}
        setActive={handleCreateWorkspaceModalOpen}
        handlePopup={handlePopup}
        workspaceIdParam={workspaceIdParam}
      />
    </>
  );
};
