import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import 'index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from 'App';
import { BrowserRouter } from 'react-router-dom';
import { AuthLogout, instance } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

instance.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

instance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response?.status === 403){
      AuthLogout();

      localStorage.removeItem('canbanUser');
      localStorage.removeItem('sessionsStatistic');
      localStorage.removeItem('ownVacancies');

      window.location.href = '/login';
    } else toast.error(error.response?.data?.message ?? error.message);
  },
);

instance.interceptors.request.use(
  (config: any) => {
    config.withCredentials = true;

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);
