import React from 'react';

import Modal from '../../../components/modal/modal.component';

import CloseSVG from '../../../svg/close.svg';

import styles from './styles.module.scss';

interface Props {
  title: string;
  active: boolean;
  setActive: () => void;
  children: React.ReactNode;
}

const WorkspaceActionModal = ({title, active, setActive, children}: Props) => {

  return (
    <Modal active={active} setActive={setActive}>
      <div className={styles.container}>
        <header className={styles.text}>
          <div className={styles.title}>
            <h2>{title}</h2>
          </div>
          <button onClick={setActive}>
            <CloseSVG />
          </button>
        </header>

        {children}
      </div>
    </Modal>
  );
};

export default WorkspaceActionModal;
