import React, { useEffect, useState } from 'react';

import { InviteUsers } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import WorkspaceActionModal from '../workspace-action-modal/workspace-action.modal';
import Button from '../../../components/button/button.component';

import Input from '../../../components/input/input.component';

import DeleteSvg from '../../../svg/delete.svg';

import styles from './styles.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
  handlePopup?: () => void;
  workspaceIdParam?: string;
}

interface InviteInput {
  email: string;
  error: string;
}

const InviteUsersToWorkspaceModal = ({active, setActive, handlePopup, workspaceIdParam}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [inputs, setInputs] = useState<InviteInput[]>([{email: '', error: ''}]);

  const { t } = useTranslation();
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleAddInput = () => setInputs([...inputs, {email: '', error: ''}]);

  const handleRemoveInput = (index: number) => {
    const newInputs = inputs.filter(
      (_, i) => i !== index,
    );
    
    setInputs(newInputs);
  };

  const handleInputChange = (index: number, value: string) => {
    const newInputs = [...inputs];

    if (value === '') 
      newInputs[index].error = 'Email cannot be empty';
    else if (!emailRegex.test(value)) 
      newInputs[index].error = 'Invalid email';
    else 
      newInputs[index].error = '';
    
    newInputs[index].email = value;

    setInputs(newInputs);
  };

  const handleInviteUsers = () => {
    const workspaceId = Number(workspaceIdParam);

    if (!workspaceId) return;

    const newInputs = inputs.map((input) => {
      if (input.email === '')
        return { ...input, error: 'Email cannot be empty' };
      else if (!emailRegex.test(input.email)) 
        return { ...input, error: 'Invalid email' };
      
      return { ...input, error: '' };
    });

    setInputs(newInputs);

    const hasError = newInputs.some((input) => input.error !== '');
    
    if (hasError) return;

    setIsLoading(true);
    
    return InviteUsers({
      workspaceId,
      emails: inputs.map((input) => input.email),
    })
      .catch(() => toast.error(t('common.oops')))
      .finally(() => {
        setIsLoading(false);
        setActive();
        if (handlePopup) handlePopup();
      });
  };
  
  useEffect(() => {
    setInputs([{email: '', error: ''}]);
  }, [active]);
  
  //todo локализация
  return (
    <WorkspaceActionModal
      title="Invite members to this workspace"
      active={active}
      setActive={setActive}
    >
      <div className={styles.list}>
        {inputs.map((input, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.input}>
              <Input
                value={input.email}
                type="email"
                onChange={
                  (value) => handleInputChange(index, value)
                }
                disabled={isLoading}
                placeholder="Enter email for invite"
              />

              <Button
                className={styles.svg}
                onClick={() => handleRemoveInput(index)}
                size="large"
                radius="normal"
                disabled={isLoading}
              >
                <DeleteSvg />
              </Button>
            </div>

            {input.error && <div className={styles.error}>{input.error}</div>}
          </div>
        ))}
      </div>

      <div className={styles.buttons}>
        <Button
          onClick={handleInviteUsers}
          disabled={isLoading || !inputs.length}
          isLoading={isLoading}
        >
          <p>Invite</p>
        </Button>
        <Button
          onClick={handleAddInput}
          disabled={isLoading || inputs.length > 10}
          isLoading={isLoading}
        >
          <p>Add input</p>
        </Button>
      </div>
    </WorkspaceActionModal>
  );
};

export default InviteUsersToWorkspaceModal;
