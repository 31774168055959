import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  GetUserToWorkspace,
  IUserToWorkspace,
} from '@EZenkof/common-recruiter-library';

import { IWorkspaceSlice } from '../workspace.slice';

interface IGet {
  userId: number;
  workspaceId: number;
}

export const getUserToWorkspaceByUserIdReducer = createAsyncThunk<IUserToWorkspace, IGet>(
  'workspace/getUserToWorkspace',
  async (filterDto, {rejectWithValue}) => {
    try {
      const usersToWorkspaces = await GetUserToWorkspace(filterDto.userId, {
        workspaceId: filterDto.workspaceId,
      });

      if (!usersToWorkspaces) throw new Error();
      
      return usersToWorkspaces;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getUserToWorkspaceByUserIdReducerPending = (state: IWorkspaceSlice) => {
  state.isLoading = true;
};

export const getUserToWorkspaceByUserIdReducerFulfilled = (state: IWorkspaceSlice, action: PayloadAction<IUserToWorkspace>) => {
  state.usersToWorkspaces = state.usersToWorkspaces.map((userToWorkspace: IUserToWorkspace) => {
    if (userToWorkspace.userId ===  action.payload.userId)
      return {...userToWorkspace, ...action.payload};
    else return userToWorkspace;
  });
  state.isLoading = false;
};

export const getUserToWorkspaceByUserIdReducerRejected = (state: IWorkspaceSlice) => {
  state.isLoading = false;
};
