import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../store/interface/store.interface';
import Modal from '../../../components/modal/modal.component';
import { FeedbackType, setFeedbackFrameType } from '../../../../store/slices/feedback-frames/feedback-frames.slice';

import Loader from '../../../components/loader/loader';

import Button from '../../../components/button/button.component';

import styles from './styles.module.scss';

const useFrameUrl = (activeType: FeedbackType) => {
  switch (activeType) {
  case 'bugReport':
    return 'https://docs.google.com/forms/d/e/1FAIpQLSePH6F76HhzXYH2RAjbXSB7GzHjWiPn7pCBIBzKbqjnH6G-gA/viewform?embedded=true';
  case 'suggestion':
    return 'https://docs.google.com/forms/d/e/1FAIpQLScCH5BhVPYPEzVsVM1JOrXdwKYPE2z7vrZzGj3jKa6Nkqaebg/viewform?embedded=true';
  case 'initialFeedback':
    return 'https://docs.google.com/forms/d/e/1FAIpQLSedqwzxB88ZcXuPFubtFPSHz-pqqivyrvRrCU8Kfoh3G0VQpg/viewform?embedded=true';
  case 'finalFeedback':
    return 'https://docs.google.com/forms/d/e/1FAIpQLSdm-PS8vhBoIbQLvksLNZpDC8H4QcywUhjgd0pJAKC007QkDw/viewform?embedded=true';
  default:
    return '';
  }
};

const useFrameHeight = (activeType: FeedbackType) => {
  switch (activeType) {
  case 'bugReport':
    return 580;
  case 'suggestion':
    return 640;
  case 'initialFeedback':
    return 690;
  case 'finalFeedback':
    return 670;
  default:
    return 640;
  }
};

const FeedbackFrameModal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const iframeRef = useRef(null);
  const dispatch = useAppDispatch();

  const { activeType } = useAppSelector((state) => state.feedbackFrames);

  const handleClose = () => dispatch(setFeedbackFrameType({ activeType: '' }));

  useEffect(() => {
    if (activeType && iframeRef.current) {
      setIsLoading(true);

      const iframe: any = iframeRef.current;
      iframe.addEventListener('load', () => setIsLoading(false));

      return () => {
        iframe.removeEventListener('load', () => setIsLoading(false));
      };
    }
  }, [activeType]);

  return (
    <Modal active={!!activeType.length} setActive={handleClose}>
      <div className={styles.container} style={{ height: useFrameHeight(activeType) }}>
        {isLoading && <Loader />}

        <iframe
          ref={iframeRef}
          title={activeType}
          src={useFrameUrl(activeType)}
          width="650"
          height="640"
          style={{ border: 'none', display: isLoading ? 'none' : 'block' }}>
          {t('common.loading')}
        </iframe>

        {!isLoading && (
          <footer>
            <Button onClick={handleClose}>
              <p>{t('buttons.close')}</p>
            </Button>
          </footer>
        )}
      </div>
    </Modal>
  );
};

export default FeedbackFrameModal;
