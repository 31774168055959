import { ListenWorkspaceEvents } from '@EZenkof/common-recruiter-library';
import { useEffect } from 'react';

export const useWorkspaceEvents = (
  onMessage: (event: MessageEvent) => void, 
  onError?: (error: any) => void,
) => {
  useEffect(() => {
    const eventSource = ListenWorkspaceEvents(onMessage, onError);

    return () => {
      eventSource.close();
    };
  }, [onMessage]);
};
