import { useState } from 'react';

type StorageValue<T> = T | null;

export const useLocalStorage = <T>(keyName: string, defaultValue: T): [StorageValue<T>, (newValue: T) => void, () => void] => {
  const [storedValue, setStoredValue] = useState<StorageValue<T>>(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value !== null) {
        return JSON.parse(value) as T;
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));

        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: T) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}

    setStoredValue(newValue);
  };

  const actualize = () => {
    const value = localStorage.getItem(keyName);

    if (value !== null) {
      const parsedValue = JSON.parse(value) as T;

      setStoredValue(parsedValue);
    } else {
      localStorage.setItem(keyName, JSON.stringify(defaultValue));

      setStoredValue(defaultValue);
    }
  };

  return [storedValue, setValue, actualize];
};
