import React, { useState } from 'react';

import { CreateWorkspace, IWorkspace, Refresh } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../common/helper/hooks/useAuth';
import Input from '../../../common/components/input/input.component';
import Button from '../../../common/components/button/button.component';

import styles from './styles.module.scss';

const OutOfWorkspacesLayer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { refresh } = useAuth();

  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleName = (value: string) => setName(value);

  const handleCreateWorkspace = () => {
    setIsLoading(true);

    return CreateWorkspace({ name })
      .then(async (createResponse) => {
        if (!createResponse) return;
        else await handleRefreshAfterCreate(createResponse);
      })
      .catch(() => toast.error(t('common.oops')))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRefreshAfterCreate = (createResponse: IWorkspace) => Refresh()
    .then((refreshResponse) => {
      refresh(refreshResponse);

      navigate(`/profile/${createResponse.id}/workspace`);
    })
    .catch(() => toast.error(t('common.oops')));
  
  //todo локализация
  return (
    <div className={styles.container}>
      <p>You haven't any workspace, create a new one</p>

      <div className={styles.input}>
        <Input
          value={name}
          onChange={handleName}
          placeholder="New workspace name"
          disabled={isLoading}
        />

        <Button
          onClick={handleCreateWorkspace}
          disabled={!name.length || isLoading}
          isLoading={isLoading}
        >
          <p>Create</p>
        </Button>
      </div>
    </div>
  );
};

export default OutOfWorkspacesLayer;
