import React, { ReactElement, Suspense } from 'react';

import { Outlet } from 'react-router-dom';
import Loader from 'common/components/loader/loader';
import HelpModal from 'common/modal/support/help/help.modal';

import Header from '../common/components/header/header.component';

import styles from './main.module.scss';

const MainLayout = (): ReactElement => {

  return (
    <>
      <Header />

      <Suspense
        fallback={
          <div className={styles.loader}>
            <Loader />
          </div>
        }>
        <main className={styles.container}>
          <Outlet />
        </main>
      </Suspense>

      <HelpModal />
    </>
  );
};

export default MainLayout;
