import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteRecruitingVacancyById } from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';


interface IDelete {
  id: number;
  workspaceId: number;
}

export const deleteVacancyReducer = createAsyncThunk< Omit<IDelete, 'workspaceId'>,IDelete>(
  'vacancies/deleteVacancy',
  async ({ id, workspaceId }, { rejectWithValue }) => {
    try {
      await DeleteRecruitingVacancyById(id, {workspaceId});

      return { id };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteVacancyReducerPending = (state: IVacanciesSlice) => {};

export const deleteVacancyReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<Omit<IDelete, 'workspaceId'>>) => {
  state.vacancies = state.vacancies.filter((v) => v.id !== action.payload.id);
  state.count = state.count - 1;
};

export const deleteVacancyReducerRejected = (state: IVacanciesSlice) => {};
