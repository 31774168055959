import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GetAvailableParseLimit } from '@EZenkof/common-recruiter-library';

import { IAvailableParseLimitSlice } from '../available-parse-limit.slice';

interface IGet {
  limit: number | null;
}

export const getAvailableParseLimitReducer = createAsyncThunk<IGet, number>(
  'availableParseLimit/getAvailableParseLimit',
  async (workspaceId, {rejectWithValue}) => {
    try {
      const result = await GetAvailableParseLimit({workspaceId});

      return { limit: result };
    }catch(error: any){
      return rejectWithValue(error.message);
    }
  },
);

export const getAvailableParseLimitReducerPending = (state: IAvailableParseLimitSlice) => {
  state.isLoading = true;
};

export const getAvailableParseLimitReducerFulfilled = (state: IAvailableParseLimitSlice, action: PayloadAction<IGet>) => {
  state.limit = action.payload.limit;
  state.isLoading = false;
};

export const getAvailableParseLimitReducerRejected = (state: IAvailableParseLimitSlice) => {
  state.isLoading = false;
};
