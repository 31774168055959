import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { CreateRecruitingVacancy, IRecruitingVacancy, ICreateRecruitingVacancyDto } from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';

interface ICreate {
  vacancy: ICreateRecruitingVacancyDto;
}

interface ICreateResult {
  vacancy: IRecruitingVacancy;
}

export const createVacancyReducer = createAsyncThunk<ICreateResult, ICreate>(
  'vacancies/createVacancy',
  async ({ vacancy }, { rejectWithValue }) => {
    try {
      const result = await CreateRecruitingVacancy(vacancy);

      if (!result) return rejectWithValue('Create error');

      return { vacancy: result };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createVacancyReducerPending = (state: IVacanciesSlice) => {
};

export const createVacancyReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<ICreateResult>) => {
  state.vacancies = [...state.vacancies, { ...action.payload.vacancy, inProgress: true }];
  state.recentVacancy = { ...action.payload.vacancy, inProgress: true };
};

export const createVacancyReducerRejected = (state: IVacanciesSlice) => {
};
