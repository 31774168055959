export const ROUTES = {
  INDEX: {
    INDEX: '/',
    PRICING: '/pricing',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    INVITE: '/registration/:inviteToken',
    ACTIVATE: '/activate',
    RECOVERY: '/recovery',
    ALL: '/*',
  },

  PROFILE: {
    INDEX: '/profile',
    VACANCIES: ':workspaceId',
    CANDIDATES: ':workspaceId/candidates',
    PAYMENTS: ':workspaceId/payments',
    WORKSPACE: ':workspaceId/workspace',
  },
};
