import { useEffect } from 'react';

const HotjarComponent = () => {

  useEffect(() => {
    if (!!process.env.REACT_APP_SENTRY_DSN)
      (function(h, o, t, j, a, r) {
        // @ts-ignore
        h.hj =
          // @ts-ignore
          h.hj ||
          function() {
            // @ts-ignore
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        // @ts-ignore
        h._hjSettings = { hjid: 5199766, hjsv: 6 };
        // @ts-ignore
        a = o.getElementsByTagName('head')[0];
        // @ts-ignore
        r = o.createElement('script');
        // @ts-ignore
        r.async = 1;
        // @ts-ignore
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        // @ts-ignore
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  return null;
};

export default HotjarComponent;
