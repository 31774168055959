import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IVacanciesGuideSlice {
  step?: number;
  vacancyId?: number;
  run?: boolean;
}

const initialState: IVacanciesGuideSlice = {};

const VacanciesGuideSlice = createSlice({
  name: 'vacanciesGuide',
  initialState,
  reducers: {
    updateVacancyGuideReducer: (state, action: PayloadAction<IVacanciesGuideSlice>) => {
      const {step, vacancyId, run} = action.payload;

      state.step = step;
      state.vacancyId = vacancyId;
      state.run = run;

      localStorage.setItem('vacanciesGuide', JSON.stringify(action.payload));
    },
    initVacancyGuideReducer: (state) => {
      const vacanciesGuide = JSON.parse(localStorage.getItem('vacanciesGuide') ?? '{}');

      const {step = 0, vacancyId = undefined, run = false} = vacanciesGuide;

      state.step = step;
      state.vacancyId = vacancyId;
      state.run = run;
    },
  },
});

export default VacanciesGuideSlice;

export const { updateVacancyGuideReducer, initVacancyGuideReducer } = VacanciesGuideSlice.actions;
