import {
  GetUsersToWorkspaces,
  GetUsersToWorkspacesCount,
  IFilterUsersToWorkspacesDto,
  IUserToWorkspace,
} from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IWorkspaceSlice } from '../workspace.slice';

interface IGetResult {
  users: IUserToWorkspace[];
  count: number;
}

export const getUsersToWorkspaceReducer = createAsyncThunk<IGetResult, IFilterUsersToWorkspacesDto>(
  'workspace/getUsersToWorkspace',
  async (filterDto, {rejectWithValue}) => {
    try {
      const [usersToWorkspaces, usersToWorkspacesCount] = await Promise.all([
        GetUsersToWorkspaces(filterDto), 
        GetUsersToWorkspacesCount(filterDto),
      ]);
      
      return { users: usersToWorkspaces ?? [], count: usersToWorkspacesCount ?? 0 };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getUsersToWorkspaceReducerPending = (state: IWorkspaceSlice) => {
  state.usersToWorkspaces = [];
  state.usersToWorkspacesCount = 0;
  state.isLoading = true;
};

export const getUsersToWorkspaceReducerFulfilled = (state: IWorkspaceSlice, action: PayloadAction<IGetResult>) => {
  state.usersToWorkspaces = action.payload.users;
  state.usersToWorkspacesCount = action.payload.count;
  state.isLoading = false;
};

export const getUsersToWorkspaceReducerRejected = (state: IWorkspaceSlice) => {
  state.isLoading = false;
};
