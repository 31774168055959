import React, { lazy, useEffect } from 'react';

import { Routes, Route } from 'react-router-dom';

import MainLayout from 'layout/main.layout';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from 'common/helper/hooks/useAuth';
import { ProtectedLayout } from 'layout/protected/protected.layout';
import FirstStartTipModal from 'common/modal/support/start-tip/support-start.modal';
import { useAppDispatch, useAppSelector } from 'store/interface/store.interface';
import { setFirstStartTipReducer, setStartTipReducer } from 'store/slices/tips/reducers/set-start-tip.reducer';
import StartTipClickModal from 'common/modal/support/start-tip/support-click.modal';
import FeedbackFrameModal from 'common/modal/support/feedback-frame/feedback-frame.modal';
import { ROUTES } from 'common/helper/consts/routes';
import * as Sentry from '@sentry/react';

import HotjarComponent from './common/components/metric/hotjar.component';
import i18n from './i18n';

const WorkspacePage = lazy(() => import('./pages/workspace/workspace.page'));
const PaymentsPage = lazy(() => import('./pages/payments/payments.page'));
const PricingPage = lazy(() => import('./pages/pricing/pricing.page'));
const AboutPage = lazy(() => import('./pages/about/about.page'));
const RecoveryPage = lazy(() => import('./pages/recovery/recovery.page'));
const ActivatePage = lazy(() => import('pages/activate/activate.page'));
const RegistrationPage = lazy(() => import('pages/registration/registration.page'));
const LoginPage = lazy(() => import('pages/login/login.page'));
const ValidatePage = lazy(() => import('pages/validate/validate.page'));
const VacanciesPage = lazy(() => import('pages/vacancies/vacancies.page'));
const InvitePage = lazy(() => import('pages/invite/invite.page'));
const NotFoundPage = lazy(() => import('pages/not-found/not-found.page'));

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN ??
    'https://94f9c00aaa7f06a20c2cd35b1316de5e@o4508259347660800.ingest.us.sentry.io/4508259349168128',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  maxBreadcrumbs: 50, // по дефолту 100
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/.*/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  const dispatch = useAppDispatch();

  const { startTip, firstStartTip } = useAppSelector((state) => state.tips);

  const handleFirstStartTip = () => {
    dispatch(setFirstStartTipReducer(!firstStartTip));
  };

  const handleStartTip = () => {
    dispatch(setStartTipReducer(!startTip));
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');

    i18n.changeLanguage(savedLanguage || 'en');
  }, []);

  useEffect(() => {
    const enableAudio = () => {
      const silentSound = new Audio(); // Создаем бесшумный звук

      silentSound.play().catch(() => {}); // Проигрываем его "в фоновом режиме"

      window.removeEventListener('click', enableAudio); // Удаляем обработчик после первого клика
    };

    window.addEventListener('click', enableAudio);
  }, []);

  //todo check and delete (?)
  useEffect(() => {
    const reloadPage = (event: ErrorEvent) => {
      if (event.message.includes('Loading CSS chunk')) {
        console.error('Failed to load CSS chunk:', event.message);

        window.location.reload();
      }
    };

    window.addEventListener('error', reloadPage);

    return () => {
      window.removeEventListener('error', reloadPage);
    };
  }, []);

  return (
    <>
      <HotjarComponent/>
      <FeedbackFrameModal />
      <FirstStartTipModal active={firstStartTip} setActive={handleFirstStartTip} />
      <StartTipClickModal active={startTip} setActive={handleStartTip} />
      <AuthProvider>
        <Routes>
          <Route path={ROUTES.INDEX.INDEX} element={<MainLayout />}>
            <Route index element={<AboutPage />} />
            <Route path={ROUTES.INDEX.PRICING} element={<PricingPage />} />
            <Route path={ROUTES.INDEX.LOGIN} element={<LoginPage />} />
            <Route path={ROUTES.INDEX.REGISTRATION} element={<RegistrationPage />} />
            <Route path={ROUTES.INDEX.INVITE} element={<InvitePage />} />
            <Route path={ROUTES.INDEX.ACTIVATE} element={<ActivatePage />} />
            <Route path={ROUTES.INDEX.RECOVERY} element={<RecoveryPage />} />
            <Route path={ROUTES.INDEX.ALL} element={<NotFoundPage />} />

            <Route path={ROUTES.PROFILE.INDEX} element={<ProtectedLayout />}>
              <Route path={ROUTES.PROFILE.VACANCIES} element={<VacanciesPage />} />
              <Route path={ROUTES.PROFILE.CANDIDATES} element={<ValidatePage />} />
              <Route path={ROUTES.PROFILE.PAYMENTS} element={<PaymentsPage />} />
              { // todo delete env
                !process.env.REACT_APP_HIDE_WORKSPACE &&
                <Route path={ROUTES.PROFILE.WORKSPACE} element={<WorkspacePage />} />
              }
            </Route>
          </Route>
        </Routes>
      </AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
