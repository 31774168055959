import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetCandidates,
  GetCandidatesCount,
  ICandidate,
  IFilterCandidatesDto,
} from '@EZenkof/common-recruiter-library';

import { IValidateTechnologySlice } from '../vatidate.slice';

interface IFilter {
  data: IFilterCandidatesDto;
  isLoadMore?: boolean; //todo delete
}

interface IFilterResult {
  candidates: ICandidate[];
  count: number;
  isLoadedMore?: boolean; //todo delete
}

export const filterCandidates = createAsyncThunk<IFilterResult, IFilter>(
  'validateSlice/filterCandidates',
  async ({ data, isLoadMore }, { rejectWithValue }) => {
    try {
      const [res, count] = await Promise.all([
        GetCandidates(data), 
        GetCandidatesCount(data),
      ]);

      return { candidates: res ?? [], count: count ?? 0, isLoadedMore: isLoadMore };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterCandidatesPending = (state: IValidateTechnologySlice) => {
  state.isLoading = true;
};

export const filterCandidatesFulfilled = (state: IValidateTechnologySlice, action: PayloadAction<IFilterResult>) => {
  const isNewProfilesFound = action.payload.count > state.count;

  if (!action.payload.isLoadedMore || isNewProfilesFound) {
    state.candidates = action.payload.candidates;
    state.count = action.payload.count;
  }

  state.isLoading = false;
};

export const filterCandidatesRejected = (state: IValidateTechnologySlice) => {
  state.isLoading = false;
};
